import { TextLinkSpan } from "@/components";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { H1, H2, H3 } from "@/components/headings";
import { ContentHeightContainer } from "@/components/layout-right-drawer";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { NormIcon } from "@/components/norm-icon";
import { ProBadge } from "@/components/special-badges";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { canEdit } from "@/lib/utils";
import { Link, useNavigate } from "@tanstack/react-router";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { toast } from "sonner";

function FallbackRender({
  error,
  resetErrorBoundary,
  value,
  onChange,
  organizationSlug,
  workspaceRole,
}: {
  error: Error;
  resetErrorBoundary: (...args: any[]) => void;
  organizationSlug: string | undefined;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { canGoBackInHistory, goBackInHistory, resetToEmptyWorkspace } =
    useAPIEditorTools({ value, onChange });

  const handleReset = async () => {
    await resetToEmptyWorkspace();
    resetErrorBoundary();
  };

  const handleGoBackInHistory = () => {
    if (canGoBackInHistory) {
      goBackInHistory();
      resetErrorBoundary();
      toast.success("Last change undone.");
    }
  };

  const isEditor = canEdit(workspaceRole);

  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <ContentHeightContainer
      role="alert"
      className="flex justify-center items-center"
    >
      <div className="w-[600px]">
        <H1 className="pb-1">😰 Failed to render</H1>
        <p className="text-muted-foreground pb-3">
          {isEditor
            ? "This can have different reasons."
            : "Unable to display the interactive view. This workspace is in an invalid state. Reach out to a workspace administrator for help."}
        </p>

        {isEditor && (
          <>
            <H2 className="pb-1">How to resolve this?</H2>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="history-fix">
                <AccordionTrigger>1. Return to a stable state</AccordionTrigger>
                <AccordionContent>
                  <p className="text-sm text-muted-foreground pb-3">
                    If you introduced breaking changes recently, you can undo
                    them and return to a previous state.
                  </p>
                  <div className="pb-3">
                    <Button
                      variant="outline"
                      disabled={!canGoBackInHistory}
                      onClick={handleGoBackInHistory}
                    >
                      <NormIcon name="Undo" size="sm" className="mr-2" />
                      Undo
                    </Button>
                    {!canGoBackInHistory && (
                      <p className="text-destructive pt-2">History is empty</p>
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="spec-valid">
                <AccordionTrigger>
                  2. Is your specification is valid?
                </AccordionTrigger>
                <AccordionContent>
                  <p className="pb-3">
                    <ProBadge
                      organizationSlug={organizationSlug}
                      className="mr-2"
                    />
                    <b>Pro users:</b>{" "}
                    <Link
                      onClick={resetErrorBoundary}
                      search={(prev) => ({
                        ...prev,
                        activeElement: {
                          type: "editor",
                        } satisfies ActiveElement,
                      })}
                    >
                      <TextLinkSpan>Navigate to the editor</TextLinkSpan>
                    </Link>{" "}
                    and fix the listed workspace errors
                  </p>
                  <p className="text-muted-foreground pb-3">
                    Alternative: Export your spec and use a 3rd party tool to
                    validate that your contract is formattted correctly.
                  </p>
                  <H3 className="pb-1">
                    Here is some info about the error we're seeing:
                  </H3>
                  <pre style={{ color: "red" }}>{error.message}</pre>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="unsupported-features">
                <AccordionTrigger>
                  3. Are you using unsupported features?
                </AccordionTrigger>
                <AccordionContent>
                  <p className="text-muted-foreground pb-3">
                    API-Fiddle does not support all features of OpenAPI and JSON
                    Schema. Instead, we're using a subset of the available
                    features to ensure your contracts are optimized for various
                    tools. However, this means the editor breaks when you are
                    using unsupported features.
                  </p>
                  <p>
                    Check out our list of{" "}
                    <TextLinkSpan onClick={() => toast.success("Comming soon")}>
                      supported features here
                    </TextLinkSpan>{" "}
                    (comming soon).
                  </p>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="danger-zone">
                <AccordionTrigger className="text-destructive">
                  Danger zone
                </AccordionTrigger>
                <AccordionContent>
                  <p className="text-muted-foreground pb-3">
                    This will cause the loss of your data.
                  </p>
                  <BtnGroup>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button variant="destructive">
                          Reset to empty workspace
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                          <AlertDialogDescription>
                            You can revert this by clicking "Undo". The reset
                            will be permanent once your history is deleted (e.g.
                            browser refresh).
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction onClick={handleReset}>
                            Reset (loose data)
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </BtnGroup>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </>
        )}
      </div>
    </ContentHeightContainer>
  );
}

export function EditorPreviewErrorBoundary({
  children,
  value,
  onChange,
  organizationSlug,
  workspaceRole,
}: PropsWithChildren<EditorInputProps> & {
  organizationSlug: string | undefined;
  workspaceRole: WorkspaceRole;
}) {
  const navigate = useNavigate();
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <FallbackRender
          error={error as Error}
          resetErrorBoundary={resetErrorBoundary}
          value={value}
          onChange={onChange}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
        />
      )}
      onReset={async (_details) => {
        await navigate({
          search: (prev) => ({
            ...prev,
            activeElement: { type: "editor" } satisfies ActiveElement,
          }),
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
