import {
  useAPIEditor,
  useAPIEditorTools,
} from "@/components/contexts/api-editor-context-hooks";
import { H4 } from "@/components/headings";
import LayoutRightDrawer, {
  ContentHeightContainer,
  LayoutSidebar,
  LayoutSidebarContent,
} from "@/components/layout-right-drawer";
import { LintOutputList } from "@/components/lint-output-list";
import { PreviewToolbarContainer } from "@/components/module-api-editor";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { ProBadge } from "@/components/special-badges";
import WaveLoading from "@/components/svg-animations/wave-loading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { tokyoNight } from "@/lib/codemirror-theme";
import { LintOutputDto } from "@/lib/main-rest-client/definitions";
import { OASDefinition } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { yaml } from "@codemirror/lang-yaml";
import { Diagnostic, linter, lintGutter } from "@codemirror/lint";
import { Link } from "@tanstack/react-router";
import CodeMirror, { EditorView } from "@uiw/react-codemirror";
import debounce from "lodash/debounce";
import { Check } from "lucide-react";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { parse, stringify } from "yaml";

function transformLintDataToDiagnostics(
  apiData: LintOutputDto,
  textValue: string
): Diagnostic[] {
  const lines = textValue.split("\n"); // Split the text into lines for offset calculation

  function calculateOffset(position: {
    line: number;
    character: number;
  }): number {
    const { line, character } = position;
    const offset = lines
      .slice(0, line - 1) // Get all lines before the target line
      .reduce((sum, currentLine) => sum + currentLine.length + 1, 0); // Sum lengths and include line breaks
    return offset + character; // Add character position in the target line
  }

  return apiData.map((item) => ({
    from: calculateOffset(item.range.start),
    to: calculateOffset(item.range.end),
    message: item.message,
    severity: item.severity === 1 ? "warning" : "error", // Map severity to CodeMirror levels
    source: item.source,
  }));
}

export function EditorPreviewEditor({
  value,
  onChange,
  workspaceRole,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  extraToolbarItems,
  activeElement,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
  activeElement: Extract<ActiveElement, { type: "editor" }>;
} & EditorInputProps) {
  const codeMirrorRef = useRef<EditorView | null>(null);
  const editorRef = useRef<EditorView | null>(null);
  const mountRef = useRef<HTMLDivElement>(null);

  const [state] = useAPIEditor();
  const [saving, setSaving] = useState(false);
  const [textValue, setTextValue] = useState(stringify(value.data));
  const { setNewEditorState } = useAPIEditorTools({ value, onChange });

  const yamlLinter = linter((view) => {
    const diagnostics: Diagnostic[] = [];
    const doc = view.state.doc.toString();

    try {
      parse(doc);
    } catch (e) {
      if (e instanceof Error) {
        diagnostics.push({
          from: 0,
          to: doc.length,
          severity: "error",
          message: e.message,
        });
      }
    }

    return diagnostics;
  });

  const scrollToLine = useCallback((lineNumber: number) => {
    const editorView = codeMirrorRef.current;
    if (editorView) {
      try {
        const line = editorView.state.doc.line(lineNumber);
        // Create a new transaction to scroll and set cursor
        const transaction = editorView.state.update({
          selection: { anchor: line.from, head: line.to },
          scrollIntoView: true,
          effects: [
            EditorView.scrollIntoView(line.from, {
              y: "center",
            }),
          ],
        });

        // Dispatch the transaction
        editorView.dispatch(transaction);
      } catch (error) {
        console.error("Error scrolling to line:", error);
      }
    } else {
      console.warn("Editor view not yet initialized");
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditorStateUpdate = useCallback(
    debounce((currentEditorValue: string) => {
      if (!mountRef.current) return;
      try {
        const parsed = parse(currentEditorValue) as OASDefinition;
        setSaving(false);
        setNewEditorState({ data: parsed, document_type: value.document_type });
      } catch (err) {
        toastError(err);
      }
    }, 1500),
    [value.document_type]
  );

  const handleEditorMount = (view: EditorView) => {
    codeMirrorRef.current = view;

    if (activeElement.scrollToLine) {
      scrollToLine(activeElement.scrollToLine);
    }
  };

  // scroll when line is updated but component not remounted
  useEffect(() => {
    if (codeMirrorRef.current && activeElement.scrollToLine) {
      scrollToLine(activeElement.scrollToLine);
    }
  }, [activeElement.scrollToLine, scrollToLine]);

  const handleChange = (v: string) => {
    setTextValue(v);
    setSaving(true);
    handleEditorStateUpdate(v);
  };

  return (
    <div ref={mountRef}>
      <PreviewToolbarContainer
        workspaceRole={workspaceRole}
        isAuthed={isAuthed}
        value={value}
        onChange={onChange}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>Editor</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>

      <LayoutRightDrawer
        placeholder={
          <LayoutSidebar className="bg-background py-0">
            <LayoutSidebarContent className="h-full flex flex-col items-stretch">
              <Card>
                <CardContent className="flex items-center justify-center pb-1 pt-2 gap-2">
                  {saving ? (
                    <div className="w-6 h-12 text-brand">
                      <WaveLoading />
                    </div>
                  ) : (
                    <div className="w-6 h-12 grid place-items-center text-brand">
                      <Check size={14} />
                    </div>
                  )}
                  <div>
                    <H4>{saving ? "Saving changes" : "Up to date"}</H4>
                  </div>
                </CardContent>
              </Card>
              <Card className="grow flex flex-col items-stretch">
                {state.lintOutput.length > 0 && (
                  <CardHeader>
                    <div>
                      <p className="text-muted-foreground text-sm pb-2">
                        Fix the issues below to improve your workspace.
                      </p>
                      <Button
                        variant="secondary"
                        disabled
                        size="sm"
                        className="w-full"
                      >
                        Fix all with one click and AI{" "}
                        <ProBadge
                          className="h-6 ml-2"
                          organizationSlug={organizationSlug}
                        />
                      </Button>
                    </div>
                  </CardHeader>
                )}
                <CardContent className="grow overflow-hidden basis-0 px-0">
                  <ScrollArea className="h-full">
                    <LintOutputList lintOutputDto={state.lintOutput} />
                  </ScrollArea>
                </CardContent>
              </Card>
            </LayoutSidebarContent>
          </LayoutSidebar>
        }
        sidebar={undefined}
      >
        <ContentHeightContainer>
          <CodeMirror
            ref={editorRef}
            onCreateEditor={handleEditorMount}
            value={textValue}
            onChange={handleChange}
            theme={tokyoNight}
            extensions={[
              yaml(),
              yamlLinter,
              lintGutter(),
              linter(() =>
                transformLintDataToDiagnostics(state.lintOutput, textValue)
              ),
            ]}
          />
        </ContentHeightContainer>
      </LayoutRightDrawer>
    </div>
  );
}
