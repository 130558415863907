import {
  ApiEditorErrorBoundary,
  ApiEditorErrorFallback,
} from "@/components/api-editor-error-boundary";
import {
  EditorMode,
  WorkspaceEditorContextState,
  WorkspaceEditorProvider,
} from "@/components/contexts/api-editor-context";
import {
  useAPIEditor,
  useAPIEditorTools,
} from "@/components/contexts/api-editor-context-hooks";
import { PreviewProvider } from "@/components/contexts/operation-preview-context";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { EmptyWorkspaceDialog } from "@/components/module-preview-pane/no-operations-in-workspace";
import { OperationPreviewContainer } from "@/components/module-preview-pane/operation-preview";
import EditorSidebarLayout from "@/components/module-visual-editor/editor-sidebar";
import { useOperation } from "@/hooks/use-operation";
import { testIds } from "@/lib/const";
import { WorkspaceDocumentDraftDto } from "@/lib/main-rest-client/definitions";
import { scoreOpenAPI } from "@/lib/oas-tools/score-openapi";
import { OASComponentsObject, OASDefinition } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { useLintOpenAPI } from "@/queries/misc";
import { debounce } from "lodash";
import isEmpty from "lodash/isEmpty";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { parse, stringify } from "yaml";
import { emptyWorkspaceExample } from "../../lib/oas-examples/empty-workspace";
import { ActionBarProvider } from "../contexts/action-bar-context";
import { ActionBar } from "../module-action-bar/action-bar";
import { Header } from "./header";
import { PreviewPanel } from "./preview-panel";

// The app becomes unusable if state.editor.oas == null
// -> we reset to an empty workspace + update history
function useLastResort(props: EditorInputProps) {
  const { oas, setNewEditorState } = useAPIEditorTools(props);

  useEffect(() => {
    if (!oas) {
      setNewEditorState({
        data: parse(emptyWorkspaceExample) as OASDefinition,
        document_type: "oas_api_3_1",
      });
    }
  }, [oas, setNewEditorState]);
}

function APIEditorContent({
  value,
  onChange,
  organizationSlug,
  workspaceSlug,
  isAuthed,
  workspaceRole,
  workspaceAlert,
  draft,
  extraToolbarItems,
  activeElement,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  workspaceAlert?: ReactNode;
  draft?: WorkspaceDocumentDraftDto;
  extraToolbarItems?: ReactNode;
  activeElement: ActiveElement;
} & EditorInputProps) {
  const { findOperationsWithInfo: getOperationsWithInfo } = useOperation({
    value,
    onChange,
  });
  useLastResort({ value, onChange });

  const [, dispatch] = useAPIEditor();
  const { mutate } = useLintOpenAPI();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMutate = useCallback(
    debounce((editorValue: OASDefinition) => {
      return mutate(
        { data: stringify(editorValue) },
        {
          onSuccess: (data) => {
            dispatch({ type: "SET_LINT_OUTPUT", payload: data });

            const score = scoreOpenAPI(data, editorValue);
            dispatch({
              type: "SET_WORKSPACE_SCORE",
              payload: { score: score.score, message: score.message },
            });
          },
        }
      );
    }, 300),
    [mutate, dispatch]
  );

  useEffect(() => {
    debouncedMutate(value.data);
  }, [value.data, debouncedMutate]);

  const componentsAreEmpty = useMemo(() => {
    if (isEmpty(value.data.components)) return true;
    const keys = [
      "parameters",
      "schemas",
      "responses",
    ] as const satisfies Array<keyof OASComponentsObject>;
    const components = value.data.components || {};
    const componentsAreEmpty = keys.every((e) => {
      if (!(e in components)) return true;
      return isEmpty(components[e]);
    });
    const tagsAreEmpty = isEmpty(value.data.tags);
    return componentsAreEmpty && tagsAreEmpty;
  }, [value.data.components, value.data.tags]);

  const allOperationsWithInfo = useMemo(
    () => getOperationsWithInfo(),
    [getOperationsWithInfo]
  );

  const isWorkspaceConsideredEmpty =
    !allOperationsWithInfo.length && componentsAreEmpty;

  return (
    <>
      {isWorkspaceConsideredEmpty &&
      !workspaceAlert &&
      !canEdit(workspaceRole) ? (
        <div className="h-full flex flex-col items-stretch">
          <Header
            value={value}
            onChange={onChange}
            workspaceSlug={workspaceSlug}
            hasOperations={!!allOperationsWithInfo.length}
            workspaceRole={workspaceRole}
            showUndo
          />
          <OperationPreviewContainer className="bg-background">
            <div
              className="h-full w-full grid place-items-center"
              data-testid={testIds.emptyLoadExample}
            >
              <span className="text-muted-foreground">
                You have read permissions to this workspace
              </span>
            </div>
          </OperationPreviewContainer>
        </div>
      ) : (
        <EditorSidebarLayout
          value={value}
          onChange={onChange}
          workspaceRole={workspaceRole}
          activeElement={activeElement}
          workspaceSlug={workspaceSlug}
          isAuthed={isAuthed}
        >
          {workspaceAlert}
          <PreviewPanel
            value={value}
            onChange={onChange}
            organizationSlug={organizationSlug}
            hasOperations={true}
            isAuthed={isAuthed}
            workspaceSlug={workspaceSlug}
            workspaceRole={workspaceRole}
            draft={draft}
            extraToolbarItems={extraToolbarItems}
            activeElement={activeElement}
          />
          <EmptyWorkspaceDialog
            value={value}
            isOpen={isWorkspaceConsideredEmpty}
            onClose={() => undefined}
            onChange={onChange}
          />
        </EditorSidebarLayout>
      )}
      <ActionBar
        value={value}
        onChange={onChange}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        activeElement={activeElement}
      />
    </>
  );
}

export function WorkspaceDocumentEditor({
  value,
  onChange,
  initialState,
  organizationSlug,
  workspaceSlug,
  isAuthed,
  workspaceRole,
  workspaceAlert,
  extraToolbarItems,
  activeElement,
  draft,
}: {
  initialState: Partial<WorkspaceEditorContextState> & {
    editorMode: EditorMode;
  };
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  draft?: WorkspaceDocumentDraftDto;
  workspaceAlert?: ReactNode;
  extraToolbarItems?: ReactNode;
  activeElement: ActiveElement;
} & EditorInputProps) {
  return (
    <ActionBarProvider>
      <WorkspaceEditorProvider initialState={initialState}>
        <PreviewProvider>
          <ApiEditorErrorBoundary
            fallback={({ resolveError, error }) => (
              <ApiEditorErrorFallback
                resolveError={resolveError}
                error={error}
                value={value}
                onChange={onChange}
              />
            )}
          >
            <APIEditorContent
              value={value}
              onChange={onChange}
              activeElement={activeElement}
              organizationSlug={organizationSlug}
              workspaceSlug={workspaceSlug}
              isAuthed={isAuthed}
              workspaceRole={workspaceRole}
              workspaceAlert={workspaceAlert}
              draft={draft}
              extraToolbarItems={extraToolbarItems}
            />
          </ApiEditorErrorBoundary>
        </PreviewProvider>
      </WorkspaceEditorProvider>
    </ActionBarProvider>
  );
}
