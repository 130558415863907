import {
  EditorState,
  WorkspaceQualityMessage,
} from "@/components/module-api-editor/types";
import { LintOutputDto } from "@/lib/main-rest-client/definitions";
import { OASDefinition } from "@/lib/types";
import defaults from "lodash/defaults";
import { createContext, PropsWithChildren, useMemo, useReducer } from "react";

export const WorkspaceEditorContext = createContext<
  readonly [WorkspaceEditorContextState, React.Dispatch<Action>] | undefined
>(undefined);

export type LegacyEditorState = {
  oas: OASDefinition;
};

export type EditorMode = "annonymous" | "authed" | "draft";

type Action =
  | {
      type: "SET_HISTORY";
      payload: EditorState[];
    }
  | {
      type: "SET_LINT_OUTPUT";
      payload: LintOutputDto;
    }
  | {
      type: "SET_WORKSPACE_SCORE";
      payload: {
        score: number;
        message: WorkspaceQualityMessage;
      };
    };

export type WorkspaceEditorContextState = {
  history: EditorState[];
  editorMode: EditorMode;
  lintOutput: LintOutputDto;
  workspaceScore: null | {
    score: number;
    message: WorkspaceQualityMessage;
  };
};

const initialState: Omit<WorkspaceEditorContextState, "workspace"> = {
  editorMode: "annonymous",
  history: [],
  lintOutput: [],
  workspaceScore: null,
};

function reducer(
  state: WorkspaceEditorContextState,
  action: Action
): WorkspaceEditorContextState {
  switch (action.type) {
    case "SET_HISTORY":
      return {
        ...state,
        history: action.payload,
      };
    case "SET_LINT_OUTPUT":
      return {
        ...state,
        lintOutput: action.payload,
      };
    case "SET_WORKSPACE_SCORE":
      return {
        ...state,
        workspaceScore: action.payload,
      };
    default:
      const _: never = action;
      throw new Error(
        `Unknown action in APIEditorContext: ${JSON.stringify(_, null, 2).toString()}`
      );
  }
}

export const WorkspaceEditorProvider = ({
  children,
  initialState: initialProvidedState,
}: PropsWithChildren<{
  initialState?: Partial<WorkspaceEditorContextState> & {
    editorMode: EditorMode;
  };
}>) => {
  const [state, dispatch] = useReducer(
    reducer,
    defaults(initialProvidedState, initialState)
  );
  const value = useMemo(() => [state, dispatch] as const, [state]);
  return (
    <WorkspaceEditorContext.Provider value={value}>
      {children}
    </WorkspaceEditorContext.Provider>
  );
};
