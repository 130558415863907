import { H2 } from "@/components/headings";
import { ActiveElement } from "@/components/module-api-editor/types";
import { ColorBadge } from "@/components/ui/color-badge";
import { LintOutputDto } from "@/lib/main-rest-client/definitions";
import { Link } from "@tanstack/react-router";

export function LintOutputList({
  lintOutputDto,
}: {
  lintOutputDto: LintOutputDto;
}) {
  const hasNoSuggestions = lintOutputDto.length === 0;
  if (hasNoSuggestions) {
    return (
      <div className="px-4 py-4">
        <H2>✅ Awesome!</H2>
        <p className="text-muted-foreground">
          We were unable to find any issues in your workspace.
        </p>
      </div>
    );
  }
  return (
    <ul className=" space-y-1">
      {lintOutputDto.map((msg) => {
        return (
          <Link
            key={JSON.stringify(msg.path) + msg.code}
            search={() => ({
              activeElement: {
                type: "editor",
                scrollToLine: msg.range.start.line || 0,
              } satisfies ActiveElement,
            })}
          >
            <li className="text-sm py-2 px-2 hover:bg-accent" role="button">
              <ColorBadge
                variant="outline"
                color={
                  msg.severity === 0
                    ? "red"
                    : msg.severity === 1
                      ? "orange"
                      : "blue"
                }
                className="mr-2 h-5"
              >
                {msg.severity === 0 ? "Error" : "Warning"}
              </ColorBadge>
              <b>{msg.code}</b>:{" "}
              <span className="text-muted-foreground">{msg.message}</span>
            </li>
          </Link>
        );
      })}
    </ul>
  );
}
