import { AxiosInstance, AxiosRequestConfig } from "axios";
import * as definitions from "./definitions";

export class ApiClient {
  public axios: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axios = axiosInstance;
  }

  public setTokenHeader(accessToken: string) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  public githubSigninOrSignup(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.TokenResponseDto>(
      "/v1/public/me/auth/github/authorized",
      options
    );
  }

  public googleSigninOrSignup(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.TokenResponseDto>(
      "/v1/public/me/auth/google/authorized",
      options
    );
  }

  public findMe(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.UserDto>("v1/users/me", options);
  }

  public patchUser(
    params: { userId: string },
    data: definitions.PatchUserDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.UserDto>(
      `/v1/users/${params.userId}`,
      data,
      options
    );
  }

  public findOrganizationByIdOrSlug(
    params: { organizationIdOrSlug: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.OrganizationDto>(
      `/v1/organizations/${params.organizationIdOrSlug}`,
      options
    );
  }

  public findWorkspacesByOrganizationSlug(
    params: { organizationSlug: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceDto[]>(
      `/v1/organizations/${params.organizationSlug}/workspaces`,
      options
    );
  }

  public createOrganization(
    data: definitions.CreateOrganizationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.OrganizationDto>(
      "/v1/organizations",
      data,
      options
    );
  }

  public patchOrganization(
    params: { organizationId: string },
    data: definitions.PatchOrganizationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.OrganizationDto>(
      `/v1/organizations/${params.organizationId}`,
      data,
      options
    );
  }

  public cloneWorkspace(
    params: { workspaceId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDto>(
      `/v1/workspaces/${params.workspaceId}/clone`,
      {},
      options
    );
  }

  public createWorkspace(
    data: definitions.CreateWorkspaceDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDto>(
      "/v1/workspaces",
      data,
      options
    );
  }

  public findWorkspaceByIdOrSlug(
    params: { workspaceSlugOrId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.UserWorkspaceDto>(
      `/v1/workspaces/${params.workspaceSlugOrId}`,
      options
    );
  }

  public patchWorkspace(
    params: { workspaceId: string },
    data: definitions.PatchWorkspaceDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.WorkspaceDto>(
      `/v1/workspaces/${params.workspaceId}`,
      data,
      options
    );
  }

  public deleteWorkspace(
    params: { workspaceId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.delete<void>(
      `/v1/workspaces/${params.workspaceId}`,
      options
    );
  }

  public findFullWorkspace(
    params: {
      organizationSlug: string;
      workspaceSlug: string;
    },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceStateDto>(
      `/v1/workspaces/${params.organizationSlug}/${params.workspaceSlug}`,
      options
    );
  }

  public createWorkspaceInvitation(
    data: definitions.CreateWorkspaceInvitationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceInvitationResultDto>(
      `/v1/workspace-invitations`,
      data,
      options
    );
  }

  public acceptWorkspaceInvitaiton(
    data: definitions.AcceptWorkspaceInvitationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<void>(
      `/v1/workspace-invitations/accept`,
      data,
      options
    );
  }

  public findUserWorkspaces(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.UserWorkspaceDto[]>(
      `/v1/workspaces`,
      options
    );
  }

  public deleteWorkspaceMember(
    params: { workspaceId: string; userId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.delete<void>(
      `/v1/workspaces/${params.workspaceId}/members/${params.userId}`,
      options
    );
  }

  public findWorkspaceMembers(
    params: { workspaceId: string },
    opations?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceMemberSummaryDto[]>(
      `/v1/workspaces/${params.workspaceId}/members`,
      opations
    );
  }

  public patchWorkspaceMember(
    params: { workspaceId: string; userId: string },
    data: definitions.PatchWorkspaceMemberDto,
    opations?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.WorkspaceMemberSummaryDto>(
      `/v1/workspaces/${params.workspaceId}/members/${params.userId}`,
      data,
      opations
    );
  }

  public createWorkspaceItem(
    data: definitions.CreateWorkspaceItemDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorksapceItemDto>(
      `/v1/workspace-items`,
      data,
      options
    );
  }

  public findWorkspaceItems(
    params: { workspaceId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorksapceItemDto[]>(
      `/v1/workspaces/${params.workspaceId}/workspace-items`,
      options
    );
  }

  public findWorkspaceDocuments(
    params: { workspaceItemId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceDocumentDto[]>(
      `/v1/workspace-items/${params.workspaceItemId}/workspace-documents`,
      options
    );
  }

  public findWorkspaceDocumentDraftsByStatus(
    params: {
      workspaceDocumentId: string;
      status: definitions.WorkspaceDocumentDraftStatus;
    },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceDocumentDraftDto[]>(
      `/v1/workspace-documents/${params.workspaceDocumentId}/workspace-document-drafts/${params.status}`,
      options
    );
  }

  public createWorkspaceDocument(
    data: definitions.CreateWorkspaceDocumentDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDocumentDto>(
      `/v1/workspace-documents`,
      data,
      options
    );
  }

  public resetWorkspaceDocumentToRemoteState(
    params: { workspaceDocumentId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDocumentDto>(
      `/v1/workspace-documents/${params.workspaceDocumentId}/remote-reset`,
      undefined,
      options
    );
  }

  public patchWorkspaceDocument(
    params: { workspaceDocumentId: string },
    data: definitions.UpdateWorkspaceDocumentDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.WorkspaceDocumentDto>(
      `/v1/workspace-documents/${params.workspaceDocumentId}`,
      data,
      options
    );
  }

  public createRepositoryAdapter(
    data: definitions.RepositoryAdapterCreateDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.RepositoryAdapterDto>(
      `/v1/repository-adapters`,
      data,
      options
    );
  }

  public findOrganizationRepositoryAdapters(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.RepositoryAdapterDto[]>(
      `v1/repository-adapters`,
      options
    );
  }

  public disconnectFromadAdapters(
    params: { workspaceItemId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<void>(
      `/v1/workspace-items/${params.workspaceItemId}/disconnect`,
      undefined,
      options
    );
  }

  public findRespositoryAdapterWorkspaceDocumentDrafts(
    params: { repositoryAdapterId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceDocumentDraftDto[]>(
      `/v1/repository-adapters/${params.repositoryAdapterId}/workspace-document-drafts`,
      options
    );
  }

  public githubAppAuthRedirect(
    params: { workspaceItemId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.GithubAuthRedirectDto>(
      `/v1/github-installations/auth/${params.workspaceItemId}/redirect`,
      options
    );
  }

  public githubAppAuthConnect(
    data: definitions.GithubAppInstallationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.GithubAppConnectDto>(
      `/v1/github-installations/auth/connect`,
      data,
      options
    );
  }

  public findWorkspaceDocumentDraft(
    params: { workspaceDocumentDraftId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceDocumentDraftDto>(
      `/v1/workspace-document-drafts/${params.workspaceDocumentDraftId}`,
      options
    );
  }

  public createWorkspaceDocumentDraft(
    data: definitions.WorkspaceDocumentDraftCreateDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDocumentDraftDto>(
      `/v1/workspace-document-drafts`,
      data,
      options
    );
  }

  public deleteWorkspaceDocumentDraft(
    params: { workspaceDocumentDraftId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.delete<void>(
      `/v1/workspace-document-drafts/${params.workspaceDocumentDraftId}`,
      options
    );
  }

  public submitWorkspaceDocumentDraft(
    params: { workspaceDocumentDraftId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDocumentDraftDto>(
      `/v1/workspace-document-drafts/${params.workspaceDocumentDraftId}/submit`,
      undefined,
      options
    );
  }

  public unlockWorkspaceDocumentDraft(
    params: { workspaceDocumentDraftId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<void>(
      `/v1/workspace-document-drafts/${params.workspaceDocumentDraftId}/unlock`,
      {},
      options
    );
  }

  public patchWorkspaceDocumentDraft(
    params: { workspaceDocumentDraftId: string },
    data: definitions.WorkspaceDocumentDraftPatchDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.WorkspaceDocumentDraftDto>(
      `/v1/workspace-document-drafts/${params.workspaceDocumentDraftId}`,
      data,
      options
    );
  }

  public checkWorkspaceDocumentDraftStatus(
    params: { workspaceDocumentDraftId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceDocumentDraftDto>(
      `/v1/workspace-document-drafts/${params.workspaceDocumentDraftId}/check-status`,
      options
    );
  }

  public findWorkspaceDocumentDraftPullRequests(
    params: { workspaceDocumentDraftId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.DocumentPullRequestDto[]>(
      `/v1/workspace-document-drafts/${params.workspaceDocumentDraftId}/document-pull-requests`,
      options
    );
  }

  public lintOpenAPIDocument(
    data: definitions.LintOpenAPIDocumentDTO,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.LintOutputDto>(
      "/v1/public/workspace-documents/openapi/lint",
      data,
      options
    );
  }
}
