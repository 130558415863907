import { WorkspaceQualityMessage } from "@/components/module-api-editor/types";
import { LintOutputDto } from "@/lib/main-rest-client/definitions";
import { OASDefinition } from "@/lib/types";

interface ScoringResult {
  score: number;
  message: WorkspaceQualityMessage;
  details: {
    errorCounts: Record<number, number>;
    criticalIssues: string[];
    warnings: string[];
    specComplexity: {
      pathsCount: number;
      schemasCount: number;
      operationsCount: number;
    };
  };
}

function getQualitativeMessage(score: number): WorkspaceQualityMessage {
  if (score >= 95) return "very good";
  if (score >= 70) return "good";
  if (score >= 50) return "needs improvement";
  return "low quality";
}

export function scoreOpenAPI(
  lintResults: LintOutputDto,
  openAPISpec: OASDefinition
): ScoringResult {
  // Categorize error impact
  const CRITICAL_ERROR_CODES = [
    // Codes that significantly impact API usability
    "oas3-schema",
    "oas3-valid-schema-name",
    "operation-description",
    "operation-operationId",
    "operation-tags",
  ];

  const MINOR_ERROR_CODES = ["oas3-missing-example", "component-description"];

  // Severity weights with more balanced approach
  const SEVERITY_WEIGHTS: Record<number, number> = {
    0: 10, // Errors (most severe)
    1: 2, // Warnings
    2: 1, // Information
    3: 0, // Hints (least severe)
  };

  // Categorize and count errors
  const errorCounts: Record<number, number> = {
    0: 0, // Errors
    1: 0, // Warnings
    2: 0, // Information
    3: 0, // Hints
  };

  const criticalIssues: string[] = [];
  const warnings: string[] = [];

  // Calculate penalties with more nuanced approach
  let totalPenalty = 0;
  lintResults.forEach((error) => {
    const severity = error.severity;
    errorCounts[severity]++;

    // Special handling for different error types
    if (CRITICAL_ERROR_CODES.includes(error.code)) {
      totalPenalty += SEVERITY_WEIGHTS[severity] * 2;
      criticalIssues.push(error.code);
    } else if (MINOR_ERROR_CODES.includes(error.code)) {
      // Minimal penalty for minor issues
      totalPenalty += SEVERITY_WEIGHTS[severity] * 0.5;
      warnings.push(error.code);
    } else {
      totalPenalty += SEVERITY_WEIGHTS[severity];
    }
  });

  // Calculate specification complexity metrics
  const specComplexity = {
    pathsCount: Object.keys(openAPISpec.paths || {}).length,
    schemasCount: Object.keys(openAPISpec.components?.schemas || {}).length,
    operationsCount: Object.values(openAPISpec.paths || {})
      .flatMap((path) => Object.keys(path || ""))
      .filter((method) =>
        ["get", "post", "put", "delete", "patch"].includes(method)
      ).length,
  };

  // Bonus for comprehensive specification
  const complexityBonus = Math.min(
    15, // Increased max bonus
    specComplexity.pathsCount * 0.5 +
      specComplexity.schemasCount * 0.5 +
      specComplexity.operationsCount * 0.5
  );

  // Calculate final score with more forgiving approach
  let finalScore = Math.max(
    10, // Minimum score of 10
    Math.round(
      100 -
        totalPenalty * 0.7 + // Reduced penalty impact
        complexityBonus
    )
  );

  // Ensure score is between 10 and 100
  finalScore = Math.min(100, Math.max(10, finalScore));

  return {
    score: finalScore,
    message: getQualitativeMessage(finalScore),
    details: {
      errorCounts,
      criticalIssues,
      warnings,
      specComplexity,
    },
  };
}
