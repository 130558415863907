import { apiClient } from "@/lib/http-utils";
import {
  LintOpenAPIDocumentDTO,
  LintOutputDto,
} from "@/lib/main-rest-client/definitions";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export function useLintOpenAPI() {
  return useMutation<LintOutputDto, AxiosError, LintOpenAPIDocumentDTO>({
    mutationFn: async (lintAPIDocumentDto) => {
      return (
        (await apiClient.lintOpenAPIDocument(lintAPIDocumentDto)).data || []
      );
    },
  });
}
