import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { LayoutRightDrawerContext } from "@/components/layout-right-drawer";
import {
  ActiveElement,
  EditorInputProps,
  EditorState,
} from "@/components/module-api-editor/types";
import { hardCodedLimits } from "@/lib/const";
import { emptyWorkspaceExample } from "@/lib/oas-examples/empty-workspace";
import { OASDefinition } from "@/lib/types";
import { oasExampleMap } from "@/lib/utils";
import { useNavigate } from "@tanstack/react-router";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useContext, useMemo, useRef } from "react";
import { parse } from "yaml";
import { WorkspaceEditorContext } from "./api-editor-context";

export function useAPIEditor() {
  const ctx = useContext(WorkspaceEditorContext);
  if (ctx == null)
    throw new Error(`Trying to use APIEditorContext outside of Provider`);

  return ctx;
}

export function useAPIEditorTools({ value, onChange }: EditorInputProps) {
  const [state, dispatch] = useAPIEditor();
  const [, actionBarDispatch] = useActionBarContext();
  const navigate = useNavigate();

  const pushToHistory = useCallback(
    (editorState: EditorState) => {
      dispatch({
        type: "SET_HISTORY",
        payload: [
          ...state.history.slice(-hardCodedLimits.maxEditorHistoryLength - 1),
          editorState,
        ],
      });
    },
    [dispatch, state.history]
  );

  const previousStateRef = useRef(value);
  previousStateRef.current = value;

  const setNewEditorState = useCallback(
    (value: EditorState, shouldPushToHistory: boolean = true) => {
      onChange(value);

      if (previousStateRef.current && shouldPushToHistory) {
        pushToHistory(previousStateRef.current);
      }
    },
    [pushToHistory, onChange]
  );

  const getOASOrError = useCallback(() => {
    const oas = value.data;
    if (!oas) throw new Error("Oas not defined");
    return oas;
  }, [value.data]);

  const setActiveElement = useCallback(
    (activeElement: ActiveElement | undefined) =>
      navigate({ search: (prev) => ({ ...prev, activeElement }) }),
    [navigate]
  );

  const canGoBackInHistory = state.history.length > 0;

  const goBackInHistory = useCallback(() => {
    const newHistory = state.history.slice();
    const lastElement = newHistory.pop();
    if (!lastElement) return;
    onChange(lastElement);
    dispatch({ type: "SET_HISTORY", payload: newHistory });
  }, [onChange, dispatch, state.history]);

  const hasNoOperations = useMemo(() => {
    const oas = getOASOrError();
    return isEmpty(oas);
  }, [getOASOrError]);

  const resetToExample = useCallback(
    async (exampleName: keyof typeof oasExampleMap) => {
      // Shuffle keys in case operation with
      // same key exists. Prevent wrong accordion states.
      setNewEditorState({
        document_type: "oas_api_3_1",
        data: parse(oasExampleMap[exampleName]) as OASDefinition,
      });
      await setActiveElement(undefined);
      actionBarDispatch({
        type: "CLOSE",
      });
    },
    [actionBarDispatch, setActiveElement, setNewEditorState]
  );

  const resetToEmptyWorkspace = useCallback(async () => {
    setNewEditorState({
      data: parse(emptyWorkspaceExample) as OASDefinition,
      document_type: "oas_api_3_1",
    });
    await setActiveElement(undefined);
    actionBarDispatch({
      type: "CLOSE",
    });
  }, [actionBarDispatch, setActiveElement, setNewEditorState]);

  return {
    oas: value.data,
    componentsObject: value.data.components || {},
    history: state.history,
    setNewEditorState,
    getOASOrError,
    canGoBackInHistory,
    goBackInHistory,
    hasNoOperations,
    setActiveElement,
    resetToExample,
    resetToEmptyWorkspace,
  };
}

export function useRightDrawerLayout() {
  const ctx = React.useContext(LayoutRightDrawerContext);

  if (ctx === undefined)
    throw new Error("Trying to use rightDrawerLayout outside of context");

  return ctx;
}
